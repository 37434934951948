@import './tailwind', './variables', './typography', './gradients', './cutstom';

body {
  background-color: $body-background-color-light-grey;
  font-family: $font-primary;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#CircleProgress circle {
  animation: anim 1s linear forwards;
}

@keyframes anim {
  0% {
    stroke-dashoffset: 0;
  }
}
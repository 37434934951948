.blue-gradient {
  background: transparent linear-gradient(180deg, #4473e7 0%, #2255d4 100%) 0%
    0% no-repeat padding-box;
}
.white-gradient {
  background: transparent linear-gradient(180deg, #ffffff 0%, #eaeaea 100%) 0%
    0% no-repeat padding-box;
}
.black-gradient {
  background: transparent linear-gradient(180deg, #595959 0%, #1f1f1f 100%) 0%
    0% no-repeat padding-box;
}

.green-gradient {
  background: transparent linear-gradient(180deg, #a8d060 0%, #8fbd3b 100%) 0%
    0% no-repeat padding-box;
}

// COLORS
$button-primary: #13D2BB;
$button-secondary: #F1F1F1;
$button-danger: #F46A53;
$button-wrong-highlight: #F37878;

$text-field-blue: #CEF0FF;

$background-grey: #E3E6E9;

// TEXT-COLORS
$black: #000000;

// BREAKPOINTS
$smallest: 576px;
$small: 767px;
$medium: 992px;
$large: 1200px;

//Z-INDEX LEVELS
$z-level-00: 2;
$z-level-01: 3;
$z-level-02: 4;
$z-level-03: 5;
$z-level-04: 6;

//BODY BACKGROUNDS
$body-background-color-light-grey: #F9F9F9;
$body-background-color-white: #ffffff;
